import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
//import { graphql } from "gatsby";
//import { StaticImage } from "gatsby-plugin-image"
import { useIntl } from "gatsby-plugin-react-intl"

const PageTemplate = ({ data, location }) => {
  const intl = useIntl();

  return(
  <Layout location={location}>
    <Seo
      title={intl.locale === "en" ? `` : ``}
      description={intl.locale === "en" ? `` : ``}
    />
    <section className="sect sect-bg-bls">
      <div className="container">
        <h1>Page Template</h1>
      </div>
    </section>
  </Layout>
)}

export default PageTemplate
